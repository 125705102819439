<template>
    <b-overlay :show='showLoader'>
        <b-card>
            <b-row>
                <b-col><h3>{{ $t("general.currentSchoolYear") }}</h3></b-col>
            </b-row>
            <b-row>
                <b-col sm="6">
                    <label for="school_years">Izberi šolsko leto</label>
                    <v-select id='school_years' :clearable='false' :options='years' label="Name" v-model="selected_year"></v-select>
                </b-col>
            </b-row>
        </b-card>
        <b-card>
            <b-row>
                <b-col><h3>{{ $t("general.school_years") }}</h3></b-col>
                <b-col class="text-right">
                    <b-button variant='warning' @click="$router.push({name: 'school-year-add'})">{{ $t('general.add_school_year')}}</b-button>
                </b-col>
            </b-row>
            <b-row class="mt-1">
                <b-col>
                    <vue-good-table :columns='columns' ref="table" :rows='years'
                                    styleClass="vgt-table striped bordered condensed"
                                    :pagination-options="{
                                        enabled: true,
                                        perPage: pageLength,
                                    }">

                        <template slot="table-row" slot-scope="props">

                            <span v-if="props.column.field == 'ID'">
                                <div class="d-flex justify-content-center">
                                    <b-button class="mr-1" @click="$router.push({name: 'school-year-edit', params: {school_year_id: props.row.ID}})" variant="warning"><feather-icon icon="EditIcon"/></b-button>
                                    <b-button @click="onDeleteButtonPressed(props.row.ID)" variant="danger"><feather-icon icon="Trash2Icon"/></b-button>
                                </div>
                            </span>
                            <span v-else>
                                {{props.formattedRow[props.column.field]}}
                            </span>
                        </template>

                        <template
                            slot="pagination-bottom"
                            slot-scope="props"
                        >

                            <div class="d-flex justify-content-between flex-wrap">
                                <div class="d-flex align-items-center mb-0 mt-1">
                                    <span class="text-nowrap ">
                                        {{ (($refs.table) ? $refs.table.currentPage : 0) * pageLength - (pageLength - 1) }} - {{ props.total - (($refs.table) ? $refs.table.currentPage : 0) * pageLength > 0 ? (($refs.table) ? $refs.table.currentPage : 0) * pageLength : props.total }} {{ $t('general.of') }} {{ props.total }}
                                    </span>

                                    <b-dropdown
                                        variant="none"
                                        no-caret>

                                        <template v-slot:button-content>
                                            <span>({{ $t('general.per_page') }}: {{pageLength}}) </span>
                                            <feather-icon
                                                icon="ChevronDownIcon"
                                                size="16"
                                                class="text-body align-middle mr-25"
                                            />
                                        </template>

                                        <b-dropdown-item :key="index" v-for="(length, index) in lengths" @click.native="setPageLength(length)">
                                            {{ length }}
                                        </b-dropdown-item>

                                    </b-dropdown>
                                </div>
                                <div>
                                    <b-pagination
                                        :value="1"
                                        :total-rows="props.total"
                                        :per-page="pageLength"
                                        first-number
                                        last-number
                                        align="right"
                                        prev-class="prev-item"
                                        next-class="next-item"
                                        class="mt-1 mb-0"
                                        @input="(value)=>props.pageChanged({currentPage:value})"
                                    >
                                        <template #prev-text>
                                            <feather-icon
                                                icon="ChevronLeftIcon"
                                                size="18"
                                            />
                                        </template>
                                        <template #next-text>
                                            <feather-icon
                                                icon="ChevronRightIcon"
                                                size="18"
                                            />
                                        </template>
                                    </b-pagination>
                                </div>
                            </div>

                        </template>
                    </vue-good-table>
                </b-col>
            </b-row>
        </b-card>

        <b-modal id="confirm-deletion-modal" :cancel-disabled="true" hide-footer >
            <div class="d-block text-center">
                <p>{{ $t('general.delete_school_year_confirmation')}}</p>
                <div>
                    <b-button variant="warning" @click="onRejectDeletion">{{ $t('general.no') }}</b-button>
                    <b-button variant="danger" class="ml-2" @click="onAcceptDeletion">{{ $t('general.yes') }}</b-button>
                </div>
            </div>
        </b-modal>
    </b-overlay>
</template>
<script>
    import {BCol, BRow, BOverlay, BCard, BButton, BModal, BPagination, BDropdown, BDropdownItem} from 'bootstrap-vue'
    import {VueGoodTable} from 'vue-good-table'
    import vSelect from 'vue-select'
    export default {
        components: {BCol,
                     BRow,
                     BOverlay,
                     BCard,
                     BButton,
                     BModal,
                     BPagination,
                     BDropdown,
                     BDropdownItem,
                     VueGoodTable,
                     vSelect
        },
        data() {
            return {
                showLoader: false,
                years: [],
                selected_year: null,
                columns: [
                    {
                        field: 'Name',
                        label: this.$t('general.school_title'),
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        field: 'Title',
                        label: this.$t('general.title'),
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        field: 'Subtitle',
                        label: this.$t('general.subtitle'),
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        field(row) { return new Date(row.StartDateRegister).toLocaleDateString() },
                        label: 'Prijava od',
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        field(row) { return new Date(row.EndDateRegister).toLocaleDateString() },
                        label: 'Prijava do',
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        field(row) { return new Date(row.StartDateTurnIn).toLocaleDateString() },
                        label: 'Oddaja od',
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        field(row) { return new Date(row.EndDateTurnIn).toLocaleDateString() },
                        label: 'Oddaja do',
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        field: 'ID',
                        label: '',
                        sortable: false
                    }
                ],

                pageLength: 10,
                lengths: [
                    '10',
                    '20',
                    '50',
                    '100'
                ],
                schoolYearId: 0
            }
        },
        methods: {
            setPageLength(length) {
                this.pageLength = length
                this.$refs.table.perPageChanged({currentPerPage:this.pageLength})
            },
            setPageLengthAward(length) {
                this.pageLengthAward = length
                this.$refs.tableAward.perPageChanged({currentPerPage:this.pageLengthAward})
            },
            getSchoolYears() {
                const thisIns = this
                thisIns.showLoader = true
                thisIns.$http.get('/v1/admin/solskoLeto/')
                    .then((response) => {
                        thisIns.years = response.data
                        for (const year of response.data) {
                            if (year.Active === 1) {
                                thisIns.selected_year = year
                            }
                        }
                        thisIns.showLoader = false
                    })
                    .catch(error => {
                        thisIns.$printError(`${thisIns.$t('general.load_error')}\n${error.message}`)
                        thisIns.showLoader = false
                    })
            },
            onDeleteButtonPressed(schoolYearId) {
                this.schoolYearId = schoolYearId
                this.$bvModal.show('confirm-deletion-modal')
            },
            onAcceptDeletion() {
                this.deleteSchoolYear(this.schoolYearId)
            },
            onRejectDeletion() {
                this.schoolYearId = 0
                this.$bvModal.hide('confirm-deletion-modal')
            },
            deleteSchoolYear(schoolYearId) {
                const thisIns  = this
                this.$bvModal.hide('confirm-deletion-modal')
                thisIns.showLoader = true

                thisIns.$http.delete(`/v1/admin/solskoLeto/${schoolYearId}`)
                    .then(() => {
                        thisIns.$printSuccess(`${thisIns.$t('general.schoolYear_successfully_deleted')}\n`)
                        setTimeout(() => {
                            thisIns.getSchoolYears()
                        }, 500)
                        thisIns.showLoader = false
                    })
                    .catch(error => {
                        thisIns.$printError(`${thisIns.$t('general.delete_error')}\n${error.message}`)
                        thisIns.showLoader = false
                    })
            }
        },
        created()  {
            this.getSchoolYears()
        },
        watch: {
            selected_year(val) {
                const thisIns = this
                thisIns.showLoader = true
                thisIns.$http.post(`/v1/admin/solskoLeto/active/${ val.ID}`)
                    .then(() => {
                        thisIns.showLoader = false
                    })
                    .catch(error => {
                        thisIns.$printError(`${thisIns.$t('general.load_error')}\n${error.message}`)
                        thisIns.showLoader = false
                    })
            }
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>